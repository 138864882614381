import React from 'react'
import logo from "../img/logo.svg";

export const IndexPageTemplate = () => (
  <div className="container">
    <div className="columns">
      <div className="column is-10 is-offset-1">
        <div className="logo">
          <a href="https://www.nationalobserver.com" title="Canada's National Observer">
            <img src={logo} alt="Canada's National Observer" style={{width: '270px', height: '100px'}}/>
          </a>
        </div>
      </div>
    </div>
  </div>
)

const IndexPage = () => (
  <div
    style={{
      display: 'flex',
      width: '100vw',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <div className="logo">
      <a href="https://www.nationalobserver.com" title="Canada's National Observer">
        <img src={logo} alt="Canada's National Observer" style={{width: '270px', height: '100px'}}/>
      </a>
    </div>
  </div>
)

export default IndexPage